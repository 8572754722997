// Angular import
import {Component, inject} from '@angular/core';
import {AuthenticationService} from "@/app/core/services/authentication.service";
import { Roles, RolesPtBr } from '@/app/core/guards/roles';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent {


  userSession: any = this.authenticationService.currentAuthorizationValue;


  

  constructor(private readonly authenticationService: AuthenticationService) {
    console.log('essa é a sessão', this.userSession)
  }


  
  componentSections = [
    {
      title: 'UI Components',
      items: [
        {
          title: 'Alerts',
          url: '#'
        },
        {
          title: 'Accordions',
          url: '#'
        },
        {
          title: 'DropDown',
          url: '#'
        },
        {
          title: 'Badges',
          url: '#'
        },
        {
          title: 'Breadcrumbs',
          url: '#'
        }
      ]
    },
    {
      title: 'Application',
      items: [
        {
          title: 'Chat',
          url: '#'
        },
        {
          title: 'Kanban',
          url: '#'
        },
        {
          title: 'Mail',
          url: '#'
        },
        {
          title: 'Calendar',
          url: '#'
        },
        {
          title: 'E-Commerce',
          url: '#'
        }
      ]
    },
    {
      title: 'Advance Components',
      items: [
        {
          title: 'Sweet Alert',
          url: '#'
        },
        {
          title: 'Light Box',
          url: '#'
        },
        {
          title: 'Modal',
          url: '#'
        },
        {
          title: 'Notification',
          url: '#'
        },
        {
          title: 'Tree View',
          url: '#'
        }
      ]
    }
  ];
  notification = [
    {
      images: 'assets/images/user/avatar-2.jpg',
      background: 'bg-light-success',
      icon: 'ti ti-building-store',
      title: 'John Doe',
      time: '2 min ago',
      text: 'It is a long established fact that a reader will be distracted',
      badgeType: true,
      mailType: false,
      imagesType: false,
      conformation: false,
      iconType: false
    },
    {
      images: 'assets/images/user/avatar-2.jpg',
      background: 'bg-light-success',
      icon: 'ti ti-building-store',
      title: 'Store Verification Done',
      time: '3 min ago',
      text: 'We have successfully received your request.',
      badgeType: true,
      mailType: false,
      imagesType: false,
      conformation: false,
      iconType: true
    },
    {
      images: 'assets/images/user/avatar-2.jpg',
      background: 'bg-light-primary',
      icon: 'ti ti-mailbox',
      title: 'Check Your Mail.',
      time: '5 min ago',
      text: "All done! Now check your inbox as you're in for a sweet treat!",
      badgeType: false,
      mailType: true,
      imagesType: false,
      conformation: false,
      iconType: true
    },
    {
      images: 'assets/images/user/avatar-2.jpg',
      background: 'bg-light-success',
      icon: 'ti ti-building-store',
      title: 'John Doe',
      time: '8 min ago',
      text: 'Uploaded two file on 21Jan 2020',
      badgeType: false,
      mailType: false,
      imagesType: true,
      conformation: false,
      iconType: false
    },
    {
      images: 'assets/images/user/avatar-3.jpg',
      background: 'bg-light-success',
      icon: 'ti ti-building-store',
      title: 'John Doe',
      time: '10 min ago',
      text: 'It is a long established fact that a reader will be distracted',
      badgeType: false,
      mailType: false,
      imagesType: false,
      conformation: true,
      iconType: false
    }
  ];

  async logout(): Promise<void> {
    await this.authenticationService.signOut()
  }

  async changementChurch(): Promise<void> {
    await this.authenticationService.changementChurch()
  }

  getRole(role: string){
    let roleName: any
    if(Roles.ADMINISTRATOR === role){
      roleName = RolesPtBr.ADMINISTRATOR
    } else if(Roles.COLLABORATOR === role){
      roleName = RolesPtBr.COLLABORATOR
    } else {
      roleName = RolesPtBr.MANAGER
    }

    return roleName
  }
}
