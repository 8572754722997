/**
 * Created by Wilton Oliveira Ferreira on 01/04/2023
 */

export enum Roles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  MANAGER = 'MANAGER',
  COLLABORATOR = 'COLLABORATOR',
}


export enum RolesPtBr {
  ADMINISTRATOR = 'ADMINISTRADOR',
  MANAGER = 'MANAGER',
  COLLABORATOR = 'COLABORADOR',
}
