import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CpfCnpjFormatPipe } from '../filter/cpf-cnpj-format.pipe';

@Component({
  selector: 'app-cpf-mask-type',
  standalone: true,
  imports: [ReactiveFormsModule, NgxMaskDirective],
  providers: [provideNgxMask(), CpfCnpjFormatPipe],
  template: `
              <label>{{to.label}}{{to.required ? ' *': ''}}</label>
              <input
                class="form-control mt-2"
                type="text"
                mask="00.000.000/0000-00"
                placeholder="00.000.000/0000-00"
                [formControl]="formControl"
              /> `
})
export class CnpjMaskTypeComponent extends FieldType<any> {
 }
