export const LOGIN_SERVICE = 'login'
export const DASHBOARD_SERVICE = 'dashboards'
export const RESET_SERVICE = 'reset-password'
export const ONBOARDING_SERVICE = 'onboarding'

export const AUTH_ROUTE = '/auth'


export const LOGIN_PAGE_ROUTE = `/guest/${LOGIN_SERVICE}`
export const RESET_PAGE_ROUTE = `/guest/${RESET_SERVICE}`
export const HOME_PAGE_ROUTE = `/admin/${DASHBOARD_SERVICE}`;
export const ONBOARDING_ROUTE = `/${ONBOARDING_SERVICE}`;

export const AUTH_TOKEN = 'so-ebd-auth-token'
export const AUTH_USER_DATA = 'so-ebd-auth-user-data'
export const AUTH_ROLE = 'so-ebd-auth-user-role'
export const AUTH_CHURCH= 'so-ebd-auth-user-church'
