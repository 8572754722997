import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import {AuthenticationService} from "@/app/core/services/authentication.service";

@Directive({
  selector: '[disableUnauthorized]'
})
export class DisableUnauthorizedDirective implements OnInit {

  @Input('disableUnauthorized') roles!: string[];
  constructor(
    private readonly el: ElementRef,
    private readonly authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentAuthorization.subscribe(credential => {
      if (!this.authenticationService.hasRole(this.roles)) {
        this.el.nativeElement.disabled = true;
      }
    })
  }

}
