<!DOCTYPE html>
<html lang="pt-BR">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Política de Privacidade</title>
  <style>
      body {
          font-family: Arial, sans-serif;
          margin: 20px;
          line-height: 1.6;
      }
      h1, h2, h3 {
          color: #2c555d;
      }
      ul {
          margin: 10px 0;
          padding-left: 20px;
      }
      a {
          color: #007BFF;
          text-decoration: none;
      }
      a:hover {
          text-decoration: underline;
      }
      .button-group {
          margin-top: 20px;
          text-align: center;
      }
      .button-group button {
          padding: 10px 20px;
          font-size: 16px;
          margin: 0 10px;
          cursor: pointer;
      }
      .accept {
          background-color: #28a745;
          color: white;
          border: none;
      }
      .accept:hover {
          background-color: #218838;
      }
      .refuse {
          background-color: #dc3545;
          color: white;
          border: none;
      }
      .refuse:hover {
          background-color: #c82333;
      }
  </style>
</head>
<body>
<h1>Política de Privacidade</h1>
<p>Este aplicativo é mantido e operado pela Igreja Cristã Maranata.</p>

<h2>1. Dados que coletamos e motivos da coleta</h2>
<p>Não existe cadastro de usuário no aplicativo. Os dados são coletados ao efetuar cadastro de usuário no portal web da Igreja Cristã Maranata no endereço
  <a href="https://portal.presbiterio.org.br" target="_blank">https://portal.presbiterio.org.br</a>.
</p>

<h3>1.1. Dados pessoais fornecidos expressamente pelo usuário</h3>
<p>Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso aplicativo:</p>
<ul>
  <li>Nome completo</li>
  <li>CPF</li>
  <li>Data de nascimento</li>
  <li>E-mail</li>
  <li>Telefone</li>
  <li>Endereço</li>
</ul>

<h2>2. Compartilhamento de dados pessoais com terceiros</h2>
<p>Nós compartilhamos alguns dos dados pessoais mencionados nesta seção com terceiros:</p>
<ul>
  <li>AlgoLity Soluções e Sistemas LTDA</li>
  <li>HelpDBA</li>
</ul>

<h2>3. Por quanto tempo seus dados pessoais serão armazenados</h2>
<p>Os dados pessoais coletados pelo aplicativo são armazenados pelo período necessário para atingir as finalidades elencadas neste documento, considerando direitos legais e regulatórios aplicáveis.</p>

<h2>4. Direitos do usuário</h2>
<p>O usuário do aplicativo possui os seguintes direitos:</p>
<ul>
  <li>Confirmação da existência de tratamento</li>
  <li>Acesso aos dados</li>
  <li>Correção de dados incompletos, inexatos ou desatualizados</li>
  <li>Anonimização, bloqueio ou eliminação de dados desnecessários</li>
  <li>Informação sobre o compartilhamento de dados</li>
  <li>Revogação do consentimento</li>
</ul>

<h2>5. Medidas de segurança no tratamento de dados pessoais</h2>
<p>Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.</p>

<h2>6. Alterações nesta política</h2>
<p>A presente versão desta Política de Privacidade foi atualizada pela última vez em 02/03/2021. Reservamo-nos o direito de modificar as normas a qualquer momento.</p>

<h2>7. Como entrar em contato conosco</h2>
<p>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato conosco.</p>
<p><strong>E-mail:</strong> <a href="mailto:sistemas@presbiterio.org.br">sistemas&#64;presbiterio.org.br</a></p>

<div class="button-group">
  <button class="accept" onclick="acceptPolicy()">Aceitar</button>
  <button class="refuse" onclick="refusePolicy()">Recusar</button>
</div>
</body>
</html>
