<div class="m-header">
  <!-- [ Header Topbar ] start -->
  <a [routerLink]="['/default/']" class="b-brand">
    <!-- ========   change your logo hear   ============ -->
    <img src="assets/images/logo-icm.svg" style="height: 36px" alt="logo-img" class="logo logo-lg" />
    <img src="assets/images/logo-icm.svg" alt="darkMode-logo" class="logo logo-white" />
  </a>
  <!-- ======= Menu collapse Icon ===== -->
  <div class="pc-h-item">
    <a
      href="javascript:"
      class="pc-head-link head-link-secondary m-0 mobile-menu"
      [ngClass]="{ on: navCollapsed }"
      id="sidebar-hide mobile-collapse"
      title="Click to menu Collapse"
      (click)="navCollapse()"
    >
      <i class="ti ti-menu-2"></i>
    </a>
  </div>
</div>
<!-- [ Header ] end -->
