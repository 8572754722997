<div class="card" [ngClass]="cardClass" #toAnimate>
  @if (!hidHeader) {
  <div class="card-header" [ngClass]="headerClass">
    @if (!customHeader) {
    <h5>{{ cardTitle }}</h5>
    } @if (!customDate) {
    <span class="d-block">{{ CardDate }}</span>
    } @if (customHeader) {
    <ng-content select=".app-card-header"></ng-content>
    } @if (customDate) {
    <ng-content select=".app-card-subheader"></ng-content>
    }
  </div>
  }
  <div class="card-block" [ngClass]="blockClass">
    <ng-content></ng-content>
  </div>
</div>
