import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  RemoveSetImageHash,
  SetImageHash
} from './imageHash.actions';

export interface imageHashModel {
  image: string;
  name: string;
}
export interface fileImageHashModel {
  files: any
}
@State<fileImageHashModel>({
  name: 'imageFile'
})
@State<imageHashModel>({
  name: 'image',
  defaults: {
    image: '',
    name: ''
  }
})
@Injectable({
  providedIn: 'root'
})
export class ImageHashState {

  @Action(SetImageHash)
  setImageHash(ctx: StateContext<imageHashModel>, action: SetImageHash) {
    ctx.patchState(action.imageHash);
  }


  @Selector()
  static getImageHash(state: imageHashModel): imageHashModel {
    return state;
  }

  @Action(RemoveSetImageHash)
  logoutCredentials(ctx: StateContext<imageHashModel>) {
    ctx.patchState(
      {
        image: '',
        name: '',
      }
    );
  }
}
