<div class="ms-auto">
  <ul class="list-unstyled">
    <li appToggleFullScreen class="pc-h-item">
      <a href="javascript:" title="click to theme view in full-screen" class="full-screen pc-head-link head-link-secondary me-0 mobile-menu"
        ><i class="feather icon-maximize"></i
      ></a>
    </li>
    <li>
      <div class="dropdown pc-h-item header-user-profile" ngbDropdown>
        <a class="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" href="javascript:" ngbDropdownToggle>
          <img src="assets/images/user/user.png" alt="user-image" class="user-avtar" />
          <span>
            <i class="ti ti-settings"></i>
          </span>
        </a>
        <div class="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">
            <a class="dropdown-item">
              <span>
                Olá, {{ userSession?.name }}<br />
                ({{ userSession?.username }})<br/><br/>
              </span>
            </a>
            <p class="text-muted"></p>
            <hr/>
              <a href="javascript:" class="dropdown-item" (click)="changementChurch()">
                <i class="ti ti-refresh me-2"></i>
                <span>Trocar de Perfil</span>
              </a>
              <a href="javascript:" class="dropdown-item" (click)="logout()">
                <i class="ti ti-logout me-2"></i>
                <span>Logout</span>
              </a>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
