import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  acceptPolicy() {
    alert('Você aceitou a política de privacidade.')
    // Adicione a lógica de aceitação aqui
  }

  refusePolicy() {
    alert('Você recusou a política de privacidade. Redirecionando para login...')
    window.location.href = '/login'
  }
}
