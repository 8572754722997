import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLogoComponent } from './theme/layout/admin/nav-bar/nav-logo/nav-logo.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { SharedModule } from './theme/shared/shared.module';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import {AuthComponent} from "@/app/theme/layout/auth/auth.component";
import {PrivacyPolicyComponent} from "@/app/pages/privacy-policy/privacy-policy.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { SnotifyService, ToastDefaults } from 'ng-alt-snotify';
import { MessageService } from 'primeng/api';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavBarComponent,
    NavLeftComponent,
    NavRightComponent,
    NavigationComponent,
    NavLogoComponent,
    NavContentComponent,
    NavGroupComponent,
    NavItemComponent,
    NavCollapseComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule, 
    SharedModule,
    BrowserAnimationsModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    MessageService
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule {}
