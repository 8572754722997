import axios from "axios"
import { AUTH_TOKEN } from "@/app/app.constant"
import { environment } from "@/environments/environment"

// Instância principal do Axios
const API = axios.create({
  baseURL: environment.apiUrl,
})

// Interceptor de Solicitação
API.interceptors.request.use(

    (config) => {
      const token = localStorage.getItem(AUTH_TOKEN)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
)

// Interceptor de Resposta
API.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error("Erro na requisição:", error)
      return Promise.reject(error)
    }
)

export const ImageAPIHashPost = axios.create({
  baseURL: '',
})

export default API
