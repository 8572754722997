import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from "@angular/core";
import {AuthenticationService} from "@/app/core/services/authentication.service";

@Directive({
  selector: '[hideUnauthorized]'
})
export class HideUnauthorizedDirective implements AfterViewInit {

  @Input('hideUnauthorized') roles!: string[];

  constructor(
    private readonly el: ElementRef,
    private readonly authenticationService: AuthenticationService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.authenticationService.currentAuthorization.subscribe(credential => {
      if (!this.authenticationService.hasRole(this.roles)) {
        try {
          this.el.nativeElement.style.display = 'none';
          this.cdr.detectChanges();
        } catch (e) {
          console.log(e);
        }
      }
    });
  }

}
