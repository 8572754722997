@for (breadcrumb of navigationList; track breadcrumb; let last = $last) {
    @if (last && breadcrumb.breadcrumbs !== false) {
      <div class="page-header">
        <div class="page-block">
          <div class="row align-items-center">
            <div class="col-md-12 d-sm-flex align-items-center">
              <div class="page-header-title">
                @for (breadcrumb of navigationList; track breadcrumb; let last = $last) {
                    @if (last) {
                      <h5 class="m-b-10">
                        {{ breadcrumb.title }}
                      </h5>
                    }
                }
              </div>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  @if (type === 'theme2') {
                    <a [routerLink]="['/default']" title="Home"><i class="ti ti-home-2"></i></a>
                  }
                  @if (type === 'theme1') {
                    <a [routerLink]="['/default']">Home</a>
                  }
                </li>
                @for (breadcrumb of navigationList; track breadcrumb) {
                    @if (breadcrumb.url !== false) {
                      <li class="breadcrumb-item">
                        <a [routerLink]="breadcrumb.url">{{ breadcrumb.title }}</a>
                      </li>
                    }
                    @if (breadcrumb.url === false && breadcrumb.type !== 'group') {
                      <li class="breadcrumb-item">
                        <a href="javascript:">{{ breadcrumb.title }}</a>
                      </li>
                    }
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    }
}
