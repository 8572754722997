<app-navigation
  class="coded-navbar pc-sidebar"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'theme-horizontal': this.berryConfig['layout'] === 'horizontal',
    'theme-compact': this.berryConfig['layout'] === 'compact',
    'mob-open': navCollapsedMob,
    'coded-trigger': submenuCollapse
  }"
  (NavCollapsedMob)="navMobClick()"
  (SubmenuCollapse)="this.submenuCollapse = !this.submenuCollapse"
></app-navigation>
<app-nav-bar (NavCollapse)="this.navCollapsed = !this.navCollapsed" (NavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pc-container">
  <div class="coded-wrapper">
    <div class="coded-content">
      <div class="coded-inner-content">
        <app-breadcrumb></app-breadcrumb>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="pc-footer">
  <div class="footer-wrapper container-fluid">
  </div>
</footer>
<app-configuration></app-configuration>
