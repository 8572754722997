// file-upload.component.ts
import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { v4 as uuidv4 } from 'uuid';
import { ImageAPIHashPost } from '../service/client-api.service';

@Component({
  selector: 'formly-field-file-upload',
  template: `
    <div class="form-group">
      <label>{{ to.label }}</label>
      <div class="mt-2">
        <input type="file" class="form-control form-control-sm" (change)="onSelectFile($event)" />
      </div>
      <div class="row" *ngIf="viewFile">
        <div *ngIf="viewFile" class="uploaded-file-info" style="font-size: 10px;">
          Arquivo carregado: <a [href]="uploadedFileUrl" target="_blank">{{ file }}</a>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadType extends FieldType implements OnInit{
  urls: any = [];
  file: any
  uploadedFileUrl: any
  viewFile: boolean = false


  constructor(private cdr: ChangeDetectorRef){
    super();
  }

  async ngOnInit() {
    // Verifica se há um valor inicial para o campo
    const initialValue = this.formControl.value;
    this.file = initialValue
    if(this.file != undefined){
      this.viewFile = true
      this.uploadedFileUrl = 'https://sywqx79kt0.execute-api.sa-east-1.amazonaws.com/img/' + this.file
    }else{
      this.viewFile = false
      this.file = ''
    }
  }

  async onSelectFile(event: Event) {
    const target: any = event.target as HTMLInputElement;
    
    if (target.files && target.files[0]) {
        var reader = new FileReader();
        const file = await this.toBase64(target.files[0])

          //let fileFinal = event?.target?.result as string
          let base64Header: any = file.substring(0, file.indexOf(','));
          if(base64Header == null || base64Header == undefined){
            base64Header = ''
          }
          const contentType = base64Header.match(/:(.*?);/)[1]; // Extrai o Content-Type
          const imageData = file.replace(/^data:\/\w+;base64,/, '');

          // Gerar nome com extensão correta
          const fileExtension = contentType.split('/')[1]; // Extrai a extensão (png, jpeg, etc.)

           const postHash = {
            image: imageData,
            path: 'zip'
          }

          
          const response = await ImageAPIHashPost.post('', postHash)
          const caminho = {
            content: 'https://sywqx79kt0.execute-api.sa-east-1.amazonaws.com/img/' + response.data.name
          }
          this.urls.push(caminho) //lista do base64
          this.formControl.setValue(response.data.name);
          this.viewFile = true;
          this.uploadedFileUrl = caminho.content;
          this.file = response.data.name
          this.cdr.detectChanges();
      }
  }

  async toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }



  generateUUID(): string {
    return uuidv4();
  }

  remove(file: File) {
    var index = this.urls.indexOf(file);
    if (index > -1) {
      this.urls.splice(index, 1);
      this.form.value.media.files = this.urls  //urls das imagens
    }
  }
}
