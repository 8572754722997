import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from '@/app/theme/layout/auth/auth.component';
import { PrivacyPolicyComponent } from '@/app/pages/privacy-policy/privacy-policy.component';
import { canActivateGuard } from '@/app/core/guards/auth-guardng.service';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'accounts',
        loadChildren: () => import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
        canActivate: [canActivateGuard]
      },
      {
        path: 'roles',
        loadChildren: () => import('./pages/roles/roles.module').then((m) => m.RolesModule),
        canActivate: [canActivateGuard]
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [canActivateGuard]
      },
      {
        path: 'church',
        loadChildren: () => import('./pages/church/church.module').then((m) => m.ChurchModule),
        canActivate: [canActivateGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/report/report.module').then((m) => m.ReportModule),
        canActivate: [canActivateGuard]
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '/guest/login',
        pathMatch: 'full',
      },
      {
        path: 'guest',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule)
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      }
    ]
  },
  {
    path: 'privacy-policy',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyComponent
      }
    ]
  },
  { path: '**', redirectTo: '/guest/login', pathMatch: 'full' } // Rota de fallback
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

