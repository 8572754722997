/**
 * Created by Wilton Oliveira Ferreira on 17/02/2023
 */
import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ImageHashState } from '../store/imageHash/imageHash.state';
import { ImageAPIHashPost } from '../service/client-api.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <form [formGroup]="demoForm" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
      <span class="mb-2" *ngIf="props.label">{{ props.label }}{{ props.required ? '*' : '' }}</span>

      <file-upload formControlName="files" [(ngModel)]="uploadedFiles" (change)="selectedFile()" multiple="true" style="max-width: 100%">
        <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
          <span *ngIf="isFileDragDropAvailable"><i class="ti ti-upload" style="font-size: 25px"></i></span>
          <span *ngIf="!isFileDragDropAvailable">click</span>
        </ng-template>

        <ng-template let-i="index" let-file="file" let-control="control" #item>
          <div class="file-info">
            
            <span class="file-name badge bg-info rounded f-12 mr-4">{{ file.name }}  {{ file.size }}</span>
            <i class="ti ti-trash" style="font-size: 15px" (click)="remove(control, file)"></i>
          </div>
        </ng-template>
      </file-upload>

    </form>
  `,
})
export class FileInputImgType extends FieldType implements OnInit {
  public uploadedFiles: Array<File> = [];
  imageUrl: string | ArrayBuffer | undefined = 'assets/images/user/user.png';
  imageWidth: number = 25;
  validatorUpdate = false;
  fileId: any;
  multFiles: any[] = [];
  gifLoad = 'assets/images/user/loading.gif';
  
  @Select(ImageHashState.getImageHash)
  filesImages$: Observable<any> | undefined;

  demoForm = new UntypedFormGroup({
    files: new UntypedFormControl(null, FileUploadValidators.filesLimit(2)),
  });

  constructor() {
    super();
  }

  ngOnInit() {
    const { model, isInvoiceFile } = this.props;
    if (model) {
      const valueImage = this.form.get(this.key.toString())?.getRawValue();
      this.updateImage(this.getInvoiceImagePath(model, valueImage, isInvoiceFile));
    }
  }

  getInvoiceImagePath(model: any, valueImage: any, isInvoiceFile: boolean): string {
    if (Array.isArray(model)) {
      for (const image of model) {
        if (isInvoiceFile && valueImage === image.invoiceFile) {
          return valueImage;
        }
      }
    } else {
      return model;
    }
    return '';
  }

  updateImage(imagePath: string) {
    if (imagePath) {
      this.validatorUpdate = true;
      this.fileId = imagePath;
      this.imageUrl = `https://6z32fdopt0.execute-api.sa-east-1.amazonaws.com/prod/img/${imagePath}`;
    }
  }

  async handleFiles(files: FileList | File[]): Promise<void> {
    this.validatorUpdate = false;
    this.imageWidth = 20;
    this.imageUrl = this.gifLoad;
    this.multFiles = [];

    for (const fileUpload of Array.from(files)) {
      const file = await this.toBase64(fileUpload);
      this.imageUrl = file;
      this.imageWidth = 25;
      await this.push(file);
    }

    if (this.multFiles.length === 0) {
      this.resetImage();
    }
  }

  async selectedFile(): Promise<void> {
      // Verifica se o número de arquivos excede o limite
      const maxFiles = 10;
      if (this.uploadedFiles.length > maxFiles) {
        alert(`Você pode enviar no máximo ${maxFiles} arquivos.`);
        this.uploadedFiles = []
      }else{
        await this.handleFiles(this.uploadedFiles);
      }
  }

  async onDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    this.isDragging = false;
    const files = event.dataTransfer?.files;
      // Verifica se o número de arquivos excede o limite
    const maxFiles = 100;
    if (files !== undefined && files.length > maxFiles) {
      alert(`Você pode enviar no máximo ${maxFiles} arquivos.`);
      this.uploadedFiles = []
    }else{
      if (files && files.length > 0) {
        await this.handleFiles(files);
      }
    }
    
  }

  async remove(control: any, file: File): Promise<void> {
    control.removeFile(file);
    this.resetImage();

    if (this.fileId) {
      this.updateImage(this.fileId);
    }
  }

  resetImage(): void {
    this.imageUrl = 'assets/images/user/user.png';
    this.imageWidth = 20;
    this.form.get(this.key.toString())?.patchValue('');
  }

  async push(file: any) {
    const { base64Header, imageData, fileName } = this.extractFileDetails(file);
    
    const data = {
      image: imageData,
      path: 'zip',
    };

    const response = await ImageAPIHashPost.post('', data);
    this.multFiles.push(response.data.name);
    this.form.get(this.key.toString())?.patchValue(this.multFiles);
  }

  extractFileDetails(file: any) {
    const base64Header = file.substring(0, file.indexOf(','));
    const contentType = base64Header.match(/:(.*?);/)[1];
    const imageData = file.replace(/^data:text\/\w+;base64,/, '');
    const fileExtension = contentType.split('/')[1];
    const fileName = `${uuidv4()}.${fileExtension}`;

    return { base64Header, imageData, fileName };
  }

  async toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  isDragging = false;

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    this.isDragging = false;
  }
}
