import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfCnpjFormat',
  standalone: true
})
export class CpfCnpjFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length <= 11) {
      // Format CPF
      return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      // Format CNPJ
      return cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  }
}