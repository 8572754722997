import {Roles} from "@/app/core/guards/roles";

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
  roleGroup: string[];
}

export const NavigationItems: NavigationItem[] = [
  {
    id: 'operation',
    title: 'Operação',
    type: 'group',
    icon: 'feather icon-align-left',
    roleGroup: [Roles.COLLABORATOR, Roles.ADMINISTRATOR, Roles.MANAGER],
    children: [
      {
        id: 'church-list',
        title: 'Igrejas',
        type: 'item',
        url: '/admin/churchs',
        classes: 'nav-item',
        icon: 'feather icon-briefcase',
        roleGroup: [Roles.ADMINISTRATOR],
      },
      {
        id: 'report-list',
        title: 'Relatórios',
        type: 'item',
        url: '/admin/reports',
        classes: 'nav-item',
        icon: 'feather icon-trending-up',
        roleGroup: [Roles.ADMINISTRATOR],
      },
      {
        id: 'account-list',
        title: 'Contas',
        type: 'item',
        url: '/admin/accounts',
        classes: 'nav-item',
        icon: 'feather icon-users',
        roleGroup: [Roles.ADMINISTRATOR],
      },
      {
        id: 'roles-list',
        title: 'Perfil',
        type: 'item',
        url: '/admin/roles',
        classes: 'nav-item',
        icon: 'feather icon-menu',
        roleGroup: [Roles.ADMINISTRATOR],
      },
    ]
  }
];
