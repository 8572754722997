import {  imageHashModel } from './imageHash.state';


export class SetImageHash {
  static readonly type = 'SetImageHash';
  constructor(public readonly imageHash: imageHashModel) { }

}


export class RemoveSetImageHash {
  static readonly type = 'RemoveSetImageHash';

  constructor() { }
}
