// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// project import
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardComponent } from './components/card/card.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { MultiSelectModule } from 'primeng/multiselect';

// bootstrap import
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbModule,
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { TextTypeComponent } from './types/text-type';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { CnpjMaskTypeComponent } from './types/cnpj-mask-type';
import { provideNgxMask } from 'ngx-mask';
import {HideUnauthorizedDirective} from "@/app/theme/shared/directive/hide-unauthorized.directive";
import {DisableUnauthorizedDirective} from "@/app/theme/shared/directive/disable-unauthorized.directive";
import { MultSelectType } from './types/mult-select.type';
import { FileInputImgType } from './types/fileInputImg.type';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FileUploadType } from './types/file-upload.type';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgScrollbarModule,
    BreadcrumbComponent,
    ToastModule,
    MultiSelectModule,
    FormlyBootstrapModule,
    FileUploadModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts') // Importa o núcleo do ECharts
    }),
    FormlyModule.forRoot({
      types: [
        { name: 'textType', component: TextTypeComponent },
        { name: 'cnpjType', component: CnpjMaskTypeComponent },
        { name: 'mult-select', component: MultSelectType },
        { name: 'FileImgServiceProviderType', component: FileInputImgType },
        { name: 'file-upload', component: FileUploadType },
      ],
      validators: [
        { name: 'min', 
          validation: async (control, field, options: any) => control.value >= options.min
        },
        { name: 'max', 
          validation: async (control, field, options: any) => control.value >= options.max
        },
      ],
      validationMessages: [{ name: 'required', message: 'Este campo é obrigatório!' }]
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    CardComponent,
    BreadcrumbComponent,
    SpinnerComponent,
    NgbModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgScrollbarModule,
    FormlyModule,
    FormlyBootstrapModule,
    ReactiveFormsModule,
    ToastModule,
    HideUnauthorizedDirective,
    DisableUnauthorizedDirective,
    HideUnauthorizedDirective,
    MultSelectType,
    MultiSelectModule
  ],
  declarations: [
    SpinnerComponent,
    SpinnerComponent,
    TextTypeComponent,
    HideUnauthorizedDirective,
    DisableUnauthorizedDirective,
    HideUnauthorizedDirective,
    MultSelectType,
    FileInputImgType,
    FileUploadType
  ],
  providers: [provideNgxMask()]
})
export class SharedModule {}
